import Link from "next/link";
import {ReactNode} from "react";
import s, {css} from "styled-components";

import {Arrow} from "../icons";

interface SectionProps {
  background: string;

  title: string;
  titleColor?: string;

  description?: string;
  descriptionColor?: string;
  descriptionMaxWidth?: string;

  children: ReactNode;
}

export function Section(props: SectionProps) {
  return (
    <SectionComponent background={props.background}>
      <SectionHeader>
        <SectionTitle color={props.titleColor} centered>
          {props.title}
        </SectionTitle>
        {props.description && (
          <SectionDescription
            color={props.descriptionColor}
            maxWidth={props.descriptionMaxWidth}
          >
            {props.description}
          </SectionDescription>
        )}
      </SectionHeader>
      {props.children}
    </SectionComponent>
  );
}

const SectionComponent = s.section<{background: string}>`
padding: 100px 0 120px;
background: ${props => props.background};

@media (max-width: 550px) {
  padding: 60px 0 80px;
}
`;

const SectionHeader = s.header`
display: flex;
flex-direction: column;
align-items: center;
margin: 0 30px;
margin-bottom: 40px;

text-align: center;

@media (max-width: 550px) {
  margin-bottom: 0;
}
`;

export const SectionTitle = s.h2<{centered?: boolean; color?: string}>`
color: ${props => props.color ?? "black"};
margin: 0 0 20px;
text-align: ${props => (props.centered ? "center" : "inherit")};

${props => props.theme.text.build("manrope", "xxl", "bold")};
`;

const SectionDescription = s.p<{color?: string; maxWidth?: string}>`
margin: 0;
max-width: ${props => props.maxWidth ?? "auto"};

text-align: center;
color: ${props => props.color ?? "black"};
${props => props.theme.text.build("nunito", "medium")};
`;

export const Features = s.div`
display: grid;
grid-template-columns: repeat(3, 1fr);
grid-column-gap: 60px;
${props => props.theme.css.contentWidth};

padding: 50px 0;
box-sizing: border-box;

@media (max-width: ${props => props.theme.widths.medium}) {
  display: block;
}
`;

export const Feature = s.div`
text-align: center;

@media (max-width: ${props => props.theme.widths.medium}) {
  margin: 0 auto;
  max-width: 400px;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 60px;
  }
}
`;

export const FeatureIcon = s.figure`
display: flex;
justify-content: center;
align-items: center;
margin: 18px 40px;
`;

export const FeatureName = s.h3`
font-family: Manrope, sans-serif;
font-weight: 700;
text-transform: uppercase;
font-size: inherit;
color: black;
margin: 18px 0;
`;

export const FeatureDescription = s.p``;

export const addHighlights = css`
  transition: all 0.2s ease;
  background: linear-gradient(
    to bottom,
    var(--color-light-blue),
    var(--color-blue)
  );
  box-shadow:
    4px 4px 0 var(--color-navy),
    -4px -4px 0 var(--color-primary);

  &:hover {
    box-shadow:
      -8px 8px 0 var(--color-navy),
      8px -8px 0 var(--color-primary);
    animation: none;
    cursor: default;
  }
`;

export const BigBoyCTA = ({
  title,
  description,
  from,
}: {
  title: string;
  description: string;
  from: string;
}) => (
  <BigBoyWrapper>
    <Link href={`/signup?from=${from}`} passHref legacyBehavior>
      <Box>
        <Info>
          <Title>
            {title} <Arrow />
          </Title>
          <Description>{description}</Description>
        </Info>
      </Box>
    </Link>
  </BigBoyWrapper>
);

const BigBoyWrapper = s.div`
display: flex;
justify-content: center;
padding: 20px 0;
`;

const Box = s.a`
display: flex;
align-items: center;
max-width: 540px;
width: 100%;
box-sizing: border-box;
padding: 40px;

color: inherit;
text-decoration: none;

background: #fff;
border-radius: 20px;

${props => props.theme.shadows.party("always")};

svg {
  flex-shrink: 0;
  display: inline-block;
  margin-top: -4px;
}

@media (max-width: calc(572px)) { // chrome bug fix: 540px + 2rem
  margin: 0 1rem;
  padding: 1.5rem;
}
`;

const Info = s.div`

@media (max-width: calc(572px)) { // chrome bug fix: 540px + 2rem
  padding-left: 1.5rem;
}
`;

const Title = s.p`
font-family: Manrope, sans-serif;
font-weight: 700;
text-transform: uppercase;
margin: 0;
color: #000;

svg {
  transform: rotate(180deg);
  margin-left: 5px;
}
`;

const Description = s.p`
margin: 10px 0 0 0;
`;
